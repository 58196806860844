<template>
  <div>
    <div class="mains" v-if="loading" v-loading="loading" element-loading-spinner="el-icon-loading"></div>
    <div v-else>
      <div class="breadcrumb">
        <el-breadcrumb separator="/">
          <el-breadcrumb-item :to="{ path: '/apkManage' }">版本列表</el-breadcrumb-item>
          <el-breadcrumb-item>新增版本</el-breadcrumb-item>
        </el-breadcrumb>
      </div>
      <div class="form-box">
        <el-form :model="form" :rules="rules" ref="ruleForm" label-width="100px">
          <div class="form-title">基本信息</div>
          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item label="所属公司" prop="companyId">
                <el-select v-model="form.companyId" placeholder="请选择所属公司" @change="handlerSelect">
                    <el-option
                    v-for="item in tableData"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id">
                    </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="是否强制更新" prop="forceId" label-width="100">
                <el-select v-model="form.forceId" placeholder="请选择是否强制更新" @change="handlerSelect">
                    <el-option
                    v-for="item in forces"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id">
                    </el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">  
            <el-col :span="21">
              <el-form-item label="更新日志" prop="updateLog">
                <el-input v-model="form.updateLog" placeholder="请输入更新日志" type="textarea" maxlength="400" show-word-limit rows="10"></el-input>
              </el-form-item>
            </el-col>
          </el-row>      
            
          <div class="form-title">上传文件</div>
          <el-upload
            action="#"
            list-type="picture-card"
            :file-list="allFileList"
            :on-change="uploadChange"
            :limit="1"
            :auto-upload="false">
            <i slot="default" class="el-icon-plus"></i>
            <div slot="file" slot-scope="{ file }">
              <div class="apk-content">
                <img src="../../img/apk.png" />
                <span class="apk-name">{{file.name}}</span>
              </div>
              <span class="el-upload-list__item-actions">
                <span
                  v-if="!disabled"
                  class="el-upload-list__item-delete"
                  @click="handleRemove(file)">
                  <i class="el-icon-delete"></i>
                </span>
              </span>
            </div>
          </el-upload>
          <div class="bottom-btn">
            <el-button type="primary" size="small" @click="$router.push('/apkManage')">取消</el-button>
            <el-button type="primary" @click="onSubmit" size="small">提交</el-button>
          </div>
        </el-form>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      loading: false,
      form: {},
      forces: [
        { id: 1, name: '是' },
        { id: 0, name: '否' }
      ],
      rules: {
        companyId: [{ required: true, message: "必填项", trigger: "change" }],
        forceId: [{ required: true, message: "必填项", trigger: "change" }],
        updateLog:[{ required: true, message: '必填项', trigger: 'blur' }],
      },
      tableData: [],
      disabled: false,
      allFileList: [],
    };
  },
  methods: {
    loadCompany() {
      this.$ajax.get("companyQueryAll").then((res) => {
        res.data.unshift({
            id:0,
            name:'所有公司'
        })
        this.tableData = res.data;
      });
    },
    handlerSelect(e) {
      this.loadTree(e)
    },
    loadTree(companyId) {
      this.$ajax.post('menuCompany', {
        id: companyId
      }).then(res=> {
        this.dataTree = res.data
      })
    },  
    onSubmit() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          if(this.allFileList.length<1) {
            return this.$message.error('请上传文件')
          }
          
          let formData = new FormData();
          formData.append("companyId", this.form.companyId);
          formData.append("force", this.form.forceId);
          formData.append("updateLog", this.form.updateLog);
          formData.append("file", this.allFileList[0].raw);
          
          this.loading = true;
          this.$ajax.post("otaCreate", formData).then((res) => {
            this.loading = false;
            this.$message.success("成功");
            this.$router.push("/apkManage");
          }).catch(err=>{
            this.loading = false
          })
        }
      })
    },
    uploadChange(file, fileList) {
      this.allFileList = fileList;
      console.log(fileList);
    },
    handleRemove(file) {
      function checkAdult(item) {
        return item.uid != file.uid;
      }
      this.allFileList = this.allFileList.filter(checkAdult);

      console.log(this.allFileList);
    },
  },
  mounted() {
    this.loadCompany()
  },
};
</script>

<style lang="less" scoped>
.mains {
  padding: 400px 0;
  height: 100%;
}
/deep/.el-loading-spinner .el-icon-loading{
  font-size: 50px;
}
.breadcrumb {
  padding: 15px 10px;
  .el-breadcrumb {
    font-size: 18px;
  }
}
.add-checkContent {
  font-size: 14px;
  color: #66b1ff;
  line-height: 50px;
  padding-left: 28px;
  span {
    cursor: pointer;
  }
}
.el-icon-close {
  color: #1494fb;
  margin-left: 20px;
  cursor: pointer;
}
.prompt {
  font-size: 14px;
  color: #9e9e9e;
  margin-top: 10px;
}
.apk-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
    .apk-name {
        width: 130px;
        text-align: center;
        word-wrap: break-word;
        font-size: 12px;
        color: #333;
        margin-top: 10px;
    }

}
</style>